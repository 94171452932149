@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Dans src/assets/styles/index.css */
.bg-neon-blue {
  background-color: #1F51FF;
}
.border-neon-blue {
  border-color: #1F51FF;
}

.text-neon-blue {
  color: #2272FF;
}

.bg-custom-black {
  background-color: #171717;
}

html {
  scroll-behavior: smooth;
}

@keyframes slideIn {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.menu-open .block {
  animation: slideIn 0.5s ease-out forwards;
}

.menu-open .block:nth-child(1) { animation-delay: 0.1s; }
.menu-open .block:nth-child(2) { animation-delay: 0.2s; }
.menu-open .block:nth-child(3) { animation-delay: 0.3s; }
.menu-open .block:nth-child(4) { animation-delay: 0.4s; }


/* Menu caché */
.menu-hidden {
  visibility: hidden;
  opacity: 0;


  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s, visibility 0s 0.5s, max-height 0.5s ease-out;
}

.menu-open {
  visibility: visible;
  opacity: 1;

  max-height: 500px;
  overflow: hidden;
  transition: opacity 0.5s, max-height 0.5s ease-out, visibility 0s 0s;
}

.hide-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.h-competences{
  height: 120vh;
}

.h-formations{
  height: 150vh;
}









