.carousel-container {
    position: relative;
    overflow: hidden;
}

.carousel-slide {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-image {
    min-width: 100%;
    height: auto;
    cursor: pointer;
}

.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}

.prev-button-enlarged,
.next-button-enlarged {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.prev-button-enlarged {
    left: 10px;
}

.next-button-enlarged {
    right: 10px;
}
